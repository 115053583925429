// src/Login.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "../Snackbar/Snackbar";
import Logo from "../../assets/main-logo.svg";
import { Container, TextField, Button, Typography, Box } from "@mui/material";
import apiService from "../../service";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [open, setOpen] = React.useState(false);

  const handleSubmit = async (event) => {
    setShowSnackbar(false);
    try {
      event.preventDefault();
      const obj = {
        email,
        password,
        withCredentials: true
      };
      // Handle login logic here
      // For simplicity, we assume login is always successful
      const response = await apiService.login(obj);
      console.log(response);
      if (response.status) {
        setSnackbarMessage(response.message);
        setSnackbarVariant("success");
        setShowSnackbar(true);
        // toast.success(response.message);
        
        localStorage.setItem("userId", response?.data?.user_id);
        localStorage.setItem("firstName", response?.data?.first_name);
        localStorage.setItem("lastName", response?.data?.last_name);
        localStorage.setItem("access", response?.data?.tenant?.access_list);
        // localStorage.setItem("tenant", response?.data?.tenant_id);
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("apiKey", response?.data?.api_key);
        localStorage.setItem("refreshToken", response?.data?.refreshToken);
        navigate("/my-ai/criminal-document");
      } else {
        setSnackbarMessage(response.message);
        setSnackbarVariant("error");
        setShowSnackbar(true);
        // toast.error(response.message);
      }
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarVariant("error");
      setShowSnackbar(true);
      // toast.error(
      //   "Error analyzing document. Please upload a valid certificate in PDF format"
      // );
      // setMessage(
      //   "Error analyzing document. Please upload a valid certificate in PDF format"
      // );
    }
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      navigate("/custom-document-validation"); // Redirect to dashboard if already logged in
    }
  }, [navigate]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <img src={Logo} height="100px" alt="logo" />
      <Typography
        style={{ marginTop: 25, fontWeight: "bolder" }}
        variant="h6"
        component="h1"
        gutterBottom
      >
        Welcome Back!
      </Typography>
      <p>Please login to continue</p>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          sx={{
            mt: 2,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "black",
              },
              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },
          }}
          margin="normal"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          sx={{
            mt: 2,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "black",
              },
              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button
          type="submit"
          variant="contained"
          // color="primary"
          fullWidth
          style={{ background: "black", marginTop: "20px", height: "50px" }}
          // sx={{}}
        >
          Login
        </Button>

        {showSnackbar && (
          <p style={{ color: "red" }}>Invid Email or Password</p>
        )}

        {/* {showSnackbar && (
        <Snackbar message={snackbarMessage}  variant={snackbarVariant} />
      )} */}
      </form>
    </div>
  );
};

export default Login;
